import './instruction.css';
import instruction from './instruction.png';

const Instruction = () => {
    return (
        <div className="instruction">
        <div className="instruction__steps">
            <div className="instruction__step">Шаг 1. Загрузите изображение, появится панель управления и ваше изображение будет на экране справа от нее</div>
            <div className="instruction__step">Шаг 2. Выберите из выпадающего списка, яйца каких гельминтов вы будете анализировать</div>
            <div className="instruction__step">Шаг 3. Нажмите кнопку анализировать, подождите, на экране появится изображение микропрепарата с вероятностями событий - обнаружения яиц</div>
            <div className="instruction__step">Шаг 4. Скачайте получившееся изображение </div>
        </div>  
        <img src={instruction} alt="" className="instruction__pict" />          
    </div>
    )
}

export default Instruction