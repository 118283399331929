import './infopage.css';
import AI from './AI.png';

const Infopage = () => {
    return (
        <div className="project">
            <img src={AI} alt="" className="project__image" />
            <div className="project__info">
                <h2 className="project__title">Обнаружение паразитов с помощью методов искусственного интеллекта</h2>
                <h3 className="project__text">В этом приложении методы искусственного интеллекта используются для анализа снимков микроперпаратов. Преимущества этой передовой технологии заключаются не только в ее высокой точности, чувствительности и специфичности, но также в коротком времени обработки. Кишечные паразитозы вызывают относительно небольшое количество инвазий в нашей стране, при этом объем скрининговых исследований для их контроля достаточно высок. Искусственный интеллект и цифровое сканирование слайдов позволят улучшить обнаружение паразитов, помогая  врачам-лаборантам.</h3>
            </div>
    </div>
    )
}
export default Infopage