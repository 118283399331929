
import './App.css';
import {Routes, Route} from 'react-router-dom';
import Header from "./components/header/Header";
// import Mainpage from './pages/mainpage/Mainpage';
import Instruction from './pages/instruction/Instruction';
// import Resultpage from './pages/resultpage/Resultpage';
import Infopage from './pages/infopage/Infopage';

import Screen from './pages/screen/Screen';

function App() {
  return (
    <div className="App">
      <Header/>
      {/* <Screen/> */}
      {/* <Mainpage/>
      <Resultpage/>
      <Infopage/>
      <Instruction/> */}
      <Routes>
        <Route path='/' element={<Screen/>}></Route>
        <Route path='/infopage' element={<Infopage/>}></Route>
        <Route path='/instruction' element={<Instruction/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
