
import './screen.css';
import React, { useState, useEffect, useRef } from 'react';
import copro from './copro.jpg';
import AI from './AI.png';
import instruction from './instruction.png';

const Screen = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [status, setStatus] = useState("");
  const [imageID, setImageID] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [resultLoading, setResultLoading] = useState(false);
  const resultRef = useRef('');
 
 
  // const imgbeforeRef =useRef();

  const handleImageChange = (event) => {
      setPreviewImage(null)
      setImageURL(null)
      setSelectedImage(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {

        setPreviewImage(reader.result);
      };
      // если несколько человек загрузили файлы?
      reader.readAsDataURL(event.target.files[0]);

      // imgbeforeRef.current.style.display = 'none';

      resultRef.current.scrollIntoView(); 
  };

  const [selectedParasite, setSelectedParasite] = useState("");

  const handleParasiteChange = (event) => {
    setSelectedParasite(event.target.value);
    // if ({selectedParasite} == ''){
    //   console.log(19)
    // }
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      setStatus("Please select an image.");
      return;
    }

  
    setResultLoading(true)

    const formData = new FormData();
    formData.append("name", selectedParasite);
    formData.append("image", selectedImage);

    fetch("http://83.172.0.23:8000/api/images/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setImageName(data.name);
        setImageID(data.id);
        setStatus("Рисунок успешно сохранен.");
        setResultLoading(false)
      })
      .catch((error) => {
        setStatus("Ошибка сохранения изображения.");
        setResultLoading(false)
      });
       
  };

  useEffect(() => {
    if (imageID !== "") {
      fetch(`http://83.172.0.23:8000/api/images/${imageID}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setImageURL(data.image_detect);
        })
        .catch((err) => {
          console.log(err.message);
        });
        
    }
    
  }, [imageID]);

  // const check = () => {
  //   // Implement your check function here
  // };

  const loadRef = useRef();
  const instructionRef = useRef();
  const infoRef = useRef();
 

    const scroll1 = (event) => {
      loadRef.current.scrollIntoView()
    }
    const scroll2 = (event) => {
      instructionRef.current.scrollIntoView()
    }
    const scroll3 = (event) => {
      infoRef.current.scrollIntoView()
    }



  return (
    <div className="container">


      <div className="subheader">
                <div className="subheader__section subheader__section_left" onClick = {scroll3} >о проекте</div>
                <div className="subheader__divider"></div>
                <div  className="subheader__section" onClick = {scroll2} >инструкция</div>
                <div className="subheader__divider"></div>
                <div className="subheader__section subheader__section_right"  onClick = {scroll1}>загрузить изображение</div>
      </div> 
      
      <div className="first" ref={loadRef}>
        <h1 className="first__title">
          OvaScreen - это веб приложение для помощи врачам в идентификации
          паразитов на фото микропрепаратов
        </h1>
        <div className="image">

        <p className="image__req">!!! Наилучший результат будет с увеличением в 100 раз (увеличение в 10 раз- линза окуляра и в 10 раз - линза объектива)</p>   
            <label className="input-file">
              <input
                id='Imagename'
                type="file"
                accept="image/*"
                name="image"
                className="input__control"
                onChange={handleImageChange}
              />
              <span>Выберите фото микропрепарата</span>
            </label>
          <p className="image__legend">
              {status && <p>{status}</p>}
          </p>
        </div>
        <h3 className="first__info">Мы собрали базу данных из тысяч фотографий яиц гельминтов и обучили на ней нейросеть.  Искуственный интеллект определяет наличие яиц гельминтов на фотографии с вероятностью более 95% </h3>
      </div>

      <div className="field" ref = {resultRef}>
        <form 
            method="post"
            encType="multipart/form-data"
            className="image__download"
            onSubmit={handleSubmit}
        >
          <label htmlFor="field-select">
            Выберите: яица какого гельминта нужно идентифицировать:
          </label>
          <select
            name="parasites"
            id="field-select"
            className="field__options"
            onChange={handleParasiteChange}
          >
            <option value="">--Выберите гельминта--</option>
            <option value="ascaris">Аскарида</option>
            <option value="opistorchis">Описторх</option>
            <option value="all">Все возможные варианты</option>
          </select>

          <button className="field__button">
            Проанализировать изображение
          </button>
          <button className="field__button">Скачать результат</button>
        </form>
        <div className="field__image" > 
          {/* <img src={copro} alt="" className="image__before" ref = {imgbeforeRef}/> */}
          {!imageURL && !previewImage && <div  className="image__preview" style = {{backgroundImage: `url('${copro}')`}}></div>}
          {!imageURL && previewImage && <div  className="image__preview" style = {{backgroundImage: `url('${previewImage}')`}}></div>}
          {resultLoading && <div className="field__preloader">
              <main>
                <svg class="ip" viewBox="0 0 256 128" width="256px" height="128px" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                      <stop offset="0%" stop-color="#5ebd3e" />
                      <stop offset="33%" stop-color="#ffb900" />
                      <stop offset="67%" stop-color="#f78200" />
                      <stop offset="100%" stop-color="#e23838" />
                    </linearGradient>
                    <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stop-color="#e23838" />
                      <stop offset="33%" stop-color="#973999" />
                      <stop offset="67%" stop-color="#009cdf" />
                      <stop offset="100%" stop-color="#5ebd3e" />
                    </linearGradient>
                  </defs>
                  <g fill="none" stroke-linecap="round" stroke-width="16">
                    <g class="ip__track" stroke="#ddd">
                      <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                      <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                    <g stroke-dasharray="180 656">
                      <path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0" d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                      <path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358" d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                  </g>
                </svg>
              </main>
            </div>}
          {imageURL && <div  className="image__preview" style = {{backgroundImage: `url('${imageURL}')`}}></div> }
        </div>
      </div>
      <div className="project" ref={infoRef}>
            <img src={AI} alt="" className="project__image" />
            <div className="project__info">
                <h2 className="project__title">Обнаружение паразитов с помощью методов искусственного интеллекта</h2>
                <h3 className="project__text">В этом приложении методы искусственного интеллекта используются для анализа снимков микроперпаратов. Преимущества этой передовой технологии заключаются не только в ее высокой точности, чувствительности и специфичности, но также в коротком времени обработки. Кишечные паразитозы вызывают относительно небольшое количество инвазий в нашей стране, при этом объем скрининговых исследований для их контроля достаточно высок. Искусственный интеллект и цифровое сканирование слайдов позволят улучшить обнаружение паразитов, помогая  врачам-лаборантам.</h3>
            </div>
      </div>
      <div className="instruction" ref={instructionRef}>
        <div className="instruction__steps">
            <div className="instruction__step">Шаг 1. Загрузите изображение, появится панель управления и ваше изображение будет на экране справа от нее</div>
            <div className="instruction__step">Шаг 2. Выберите из выпадающего списка, яйца каких гельминтов вы будете анализировать</div>
            <div className="instruction__step">Шаг 3. Нажмите кнопку анализировать, подождите, на экране появится изображение микропрепарата с вероятностями событий - обнаружения яиц</div>
            <div className="instruction__step">Шаг 4. Скачайте получившееся изображение </div>
        </div>  
        <img src={instruction} alt="" className="instruction__pict" />          
      </div>
    </div>
  );
};

export default Screen;