import './header.css'
import {NavLink} from 'react-router-dom';

import logo from './microscopeLogo.jpg'

const Header = () => {

    return (
        <div className="header">
            
                <NavLink to='/' className="header__logo">
                    <img src={logo} alt="" className="header__pict" />
                    <div className="header__logoName">
                        OvaScreen
                    </div>
                </NavLink>
                <div className="header__upper">
                    <div className="header__link">Технические решения для микроскопии</div>
                    <div className="header__link">Личный кабинет</div>
                    <div className="header__link">Контакты</div>
                </div>
            {/* <div className="header__subheader">
                <NavLink to='/infopage' className="header__section header__section_left" >о проекте</NavLink>
                <div className="header__divider"></div>
                <NavLink to='/instruction' className="header__section" >инструкция</NavLink>
                <div className="header__divider"></div>
                <div className="header__section header__section_right">загрузить изображение</div>
            </div>        */}
        </div>
    )
}
export default Header